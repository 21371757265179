$black : #1D1D1E;
$black-secondary: #36363659;
$text-color : #F9F5DD;
$text-secondary:#F9F5DD87;
// $gray:#FFF8E014;
$gray:#2F2E2E;
$yellow:#EBC846;
$yellow-1 :#D0A93F;

$blue: #3E90F0;

$gradient-primary : linear-gradient(109.99deg, #BD8C2A 0%, #F3E166 95.4%);;
$gradient1:  linear-gradient(243.87deg, #EC804F 30.92%, #DFA613 88.55%);
$gradient2:  linear-gradient(243.87deg, #6CB528 30.92%, #17D7B6 88.55%);
$gradient3:  linear-gradient(243.87deg, #E1AABC 30.92%, #A662D5 88.55%);
$gradient4:  linear-gradient(243.87deg, #F5D545 30.92%, #F8898C 88.55%);

