.nav-link{
    span.active{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            top: 0;
            z-index: 9;
            box-shadow: 0px 10px 40px rgb(255, 182, 98);
        }
    }
}