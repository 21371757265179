.active-service{
    .services-container{
        height: auto;
        .service-card{
            display: flex !important;
        }
    }
}

.services-container{
    height: 0;
    transition: all .8s ease;

    .service-card{
        display: none !important;
    }
}