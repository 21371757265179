.ac-modes {
  svg path {
    fill: #fff;
  }

  .selected-mode {
    color: $black;
    background: $yellow;
    svg path {
      fill: $black;
    }
  }
}
.temprature-range {
  width: 56px;
  height: 100%;
  max-height: 220px;
  overflow: hidden;
  background: #f80;
  position: relative;
  border-radius: 1rem;
  border: 1px solid #ccc;
  color: $black;

  span{
    bottom: 0.5rem;
    background: $yellow;
  }
  /********** Range Input Styles **********/
  /*Range Reset*/
  input[type="range"] {
    position: absolute;
    top: -20px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 70px;
    height: 15rem;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  /* Removes default focus */
  input[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: $yellow;
    border-radius: 0.5rem;
    height: 0.5rem;
    width: 70px;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */

    /*custom styles*/
    background-color: $yellow;
    height: 2rem;
    width: 70px;
    box-shadow: 0 145px 0 90px $black;
  }

  input[type="range"]:focus::-webkit-slider-thumb {
    // border: 1px solid #053a5f;
    // outline: 3px solid #053a5f;
    // outline-offset: 0.125rem;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
  }

  input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
  }
}
