@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import "./assets//styles/all.scss";

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $black;
  color: $text-color;
}
.container {
  position: relative;
  width: 100vw;
  max-width: 600px;
  margin: auto;
  padding: 1rem;
}

.max-w-700 {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.bg-black {
  background: $black;
}
.bg-yellow {
  background: $yellow;
}
.text-light {
  color: $text-color !important;
}

.text-secondary {
  color: $text-secondary;
}

.text-yellow {
  color: $yellow;
}

.bg-gray {
  background: $gray;
}

.border-gradient {
  border: 1px solid gray;
  position: relative;
  border-radius: 22px;
  z-index: 0;
  div {
    position: relative;
    z-index: 11;
  }

  &::before {
    position: absolute;
    border-radius: 22px 22px 0 0;
    content: "";
    left: -1px;
    top: 0;
    width: calc(100% + 2px);
    height: 25px;
    z-index: 1;
  }

  &::after {
    position: absolute;
    border-radius: 22px 22px 0 0;
    content: "";
    left: 0;
    top: 4px;
    z-index: 2;
    width: 100%;
    height: 25px;
    background: $gray;
  }

  &.gradient-1::before {
    background: $gradient1;
  }
  &.gradient-2::before {
    background: $gradient2;
  }
  &.gradient-3::before {
    background: $gradient3;
  }
  &.gradient-4::before {
    background: $gradient4;
  }
}

.p-datepicker-calendar {
  .p-datepicker-title {
    button {
      color: #fff;
    }
  }
  tbody {
    td {
      background: rgb(150, 150, 150);
      padding: 10px;
    }
  }
}
