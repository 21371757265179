input{
    width: 100%;
    border-radius: 11px;
    border: none;
    font-size: 16px;
    padding: 10px;
    background: $black-secondary;
    color: $text-color;
    margin: 10px 0;
}
.p-inputotp{
    display: flex;
    gap: 10px;
    justify-content: start;

    input{
        width: 46px;
    }
}