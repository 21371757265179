.onboarding-container{
    width: 100vw;
    max-width: 600px;
    margin: auto;
    position: relative;
    svg{
        width: 100%;
    }
    img{
        width: 100%;
    }
}