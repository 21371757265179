.main-button{
    width: 80%;
    margin: auto;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: $black;
    display: inline-block;
    background: $gradient-primary;
    border-radius: 25px;
    border: 0;
}

.btn-secondary{
    border: 1px solid $yellow !important;
    color: $yellow;
    min-width: fit-content;
    width: fit-content;
    min-width: 100px;
}
.button-position{
    .btn-outer{
        width: 100%;
        max-width: 600px;
        margin: auto;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;

        .icon-container{
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            opacity: 0;
            top: 3px;
            transition: .4s;
            &:has(.icon-checked){
                right: 3px;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
        }

        &:has(.icon){
            background: #ccc;
            &::before{
                height: 24px;
                width: 24px;
                left: 0px;
                bottom: 0px;
            }
        }

        &.circle-blue{
            &::before{
                background: $blue;
            }
        }   
         
    }

    input:checked + .slider {
        background-color: $yellow;
        &:has(.icon){
            background: #ccc !important;
            &::before{
                height: 24px;
                width: 24px;
                right: 0px;
                bottom: 0px;
            }
        }
        &.circle-yellow{
            &::before{
                background: $yellow;
            }
        }  

        .icon-container{
            opacity: 1;
        }

        &:before {
            transform: translateX(16px);
        }

        
    }
}