.message-container{
    position: fixed;
    bottom: 30px;
    width: 90%;
    margin: auto;
    background: #fff;
    display: flex;
    align-items: end;
    padding: 8px;
    justify-content: space-between;
    border-radius: 40px;

    .input-container {
        width: 90%;
        border-radius: 5px;
        background-color: white;
        color: $black;
    }
    
    .input-box {
        width: 100%;
        min-height: 40px;
        max-height: 150px;
        overflow-y: auto;
        padding: 10px;
        font-size: 16px;
        outline: none;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    
    .input-box:empty:before {
        content: attr(aria-placeholder);
        color: #999;
    }

    .btn-box{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
.chat-container{
    max-height: calc(100vh - 190px);
    overflow: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
}
.receptionist-message{
    width: max-content;
    max-width: 80%;
    border-radius: 28px 28px 28px 0;
    background: #fff;
    padding: 12px;
    margin: 10px 0;
    color: $black;
}
.user-message{
    width: max-content;
    max-width: 80%;
    border-radius: 28px 0 28px 28px;
    background: $yellow-1;
    padding: 12px;
    margin: 10px 0 10px auto;
    color: $black;
}